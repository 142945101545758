// export const AIRI_BASE_URL = ".aube.cc";


// export const AIRI_BASE_URL = "https://aigc.aube.cc:58013";
// export const AIRI_BASE_URL_WS = "aigc.aube.cc:58013"
// export const AIRI_BASE_URL_INFO = "https://aigc.aube.cc:58013";
// export const AIRI_BASE_URL = "http://54.222.157.190";
// export const AIRI_BASE_URL_WS = "54.222.157.190"
// export const AIRI_BASE_URL_INFO = "http://54.222.157.190";
// export const AIRI_BASE_URL = "https://airilab.com";
// export const AIRI_BASE_URL_WS = "airilab.com"
// export const AIRI_BASE_URL_INFO = "https://airilab.com";
export const AIRI_BASE_URL = "https://airilab.net";
export const AIRI_BASE_URL_WS = "airilab.net"
export const AIRI_BASE_URL_INFO = "https://airilab.net/";

export const AIRI_AWS_URL = "https://airi-assets.s3.cn-north-1.amazonaws.com.cn";
export const AIRI_AWS_URL1 = "https://airi-development.s3.cn-north-1.amazonaws.com.cn";
// export const AIRI_AWS_URL = "https://airi-assets-sg1.s3.ap-southeast-1.amazonaws.com";
// export const AIRI_AWS_URL1 = "https://airi-assets-sg1.s3.ap-southeast-1.amazonaws.com";
// export const AIRI_AWS_URL = "https://airi-assets-fr1.s3.eu-west-3.amazonaws.com/";
// export const AIRI_AWS_URL1 = "https://airi-assets-fr1.s3.eu-west-3.amazonaws.com/";

export const BaseURL = {
  // aube.cc
  BASE_URL: `${AIRI_BASE_URL}/api`,
  WORKFLOW_BASE_URL: `${AIRI_BASE_URL}/api`,
  ADMIN_FLOW: `${AIRI_BASE_URL}/api`,
  MONGO_DB: `${AIRI_BASE_URL}/api`,
  CHAT_GPT: `${AIRI_BASE_URL}/api`,
  SUBSCRIPTION: `${AIRI_BASE_URL}/api`,
  PAYMENT: `${AIRI_BASE_URL}/api`,
  TIRES: `${AIRI_BASE_URL}/api`,
  TEAMS: `${AIRI_BASE_URL}/api`,
  MJ: `${AIRI_BASE_URL}/api`,

  // oem.aube.cc
  // BASE_URL: `https://aigcuserapioem.aube.cc/api`,
  // WORKFLOW_BASE_URL: `https://aigcworkflowapioem.aube.cc/api`,
  // ADMIN_FLOW: `https://aigcadminoem.aube.cc/api`,
  // MONGO_DB: `https://aigcmongodboem.aube.cc/api`,
  // CHAT_GPT: `https://aigcgptoem.aube.cc/api`,
  // SUBSCRIPTION: `https://aigcusersubscriptionoem.aube.cc/api`,
  // PAYMENT: `https://aigcpaymentoem.aube.cc/api`,
  // TIRES: `https://aigctiersapi.aube.cc/api`,
  // TEAMS: `https://aigcteams.aube.cc/api`,

  // AiriLab
  // BASE_URL: `https://aigcuserapi${AIRI_BASE_URL}/api`,
  // WORKFLOW_BASE_URL: `https://aigcworkflowapi${AIRI_BASE_URL}/api`,
  // ADMIN_FLOW: `https://aigcadmin${AIRI_BASE_URL}/api`,
  // MONGO_DB: `https://aigcmongodb${AIRI_BASE_URL}/api`,
  // CHAT_GPT:`https://aigcgpt${AIRI_BASE_URL}/api`,
  // SUBSCRIPTION: `https://aigcusersubscription${AIRI_BASE_URL}/api`,
  // PAYMENT:`https://aigcpayment${AIRI_BASE_URL}/api`,
  // TIRES: `https://tiers${AIRI_BASE_URL}/api`,
  // TEAMS: `https://teams${AIRI_BASE_URL}/api`,
};

export const API_CONSTANTS = {
  LOGIN: `${BaseURL.BASE_URL}/Accounts/Login`,
  GET_CURRENT_USER: `${BaseURL.BASE_URL}/Accounts/GetCurrentUser`,
  EDIT_CURRENT_USER_DETAILS: `${BaseURL.BASE_URL}/Accounts/EditUserDetails`,
  CHANGE_PROFILE_PICTURE: `${BaseURL.BASE_URL}/Accounts/ChangeProfilePic`,
  GET_ALL_TUTORIALS: `${BaseURL.ADMIN_FLOW}/Admin/GetAllTutorialsForAdmin`,
  GET_ALL_NEWS_WITH_PAGINATION: `${BaseURL.ADMIN_FLOW}/Admin/GetAllNewsForAdmin`,
  GET_PHONE_NUMBER_LENGTH_WITH_COUNTRY: `${BaseURL.BASE_URL}/Accounts/GetCountry`,
  GENERATE_WORK_FLOW_TEXT: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/Text`,
  GENERATE_WORK_FLOW_DESIGN: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/Design`,
  GENERATE_WORK_FLOW_IMAGE: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/Image`,
  GENERATE_WORK_FLOW_TEXT_MJ: `${BaseURL.WORKFLOW_BASE_URL}/Midjourney/Inspire`,
  GENERATE_WORK_FLOW_STYLIZE: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/Stylize`,
  GENERATE_WORK_FLOW_UPSCALE: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/Upscale`,
  GENERATE_WORK_FLOW_UPSCALE_FEED: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/UpscaleFeed`,
  GENERATE_WORK_FLOW_ERASER: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/Eraser`,
  GENERATE_WORK_FLOW_UPSCALE_EXTEND: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/GenerateWorkflowOutpainting`,
  GENERATE_WORK_FLOW_UPSCALE_CREATIVE: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/UpscaleScaleFeedCreative`,
  GENERATE_WORK_FLOW_ATMOSHPHERE_SWIFT: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/AtmosphereSwift`,
  GENERATE_WORK_FLOW_UPSCALE_CHARACTER: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/UpscaleScaleFeedPeople`,
  FAVORITE_IMAGE_OR_NOT: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/FavoriteOrNot`,
  GENERATE_WORK_FLOW_VIDEO: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/generateVideo`,
  CREATE_OR_UPDATE_PROJECT: `${BaseURL.BASE_URL}/Workflow/CreateorUpdateProject`,
  GET_ALL_PROJECTS_FOR_USER: `${BaseURL.BASE_URL}/Accounts/GetAllProjectsUser`,
  GET_PROJECT_DETAILS_BY_ID: `${BaseURL.BASE_URL}/Accounts/GetProjectsById`,
  GET_DESIGN_LIBRARY: `${BaseURL.BASE_URL}/Admin/GetDesignLibrary`,
  GET_FIRST_TIER: `${BaseURL.TIRES}/Master/GetFirstTier`,
  GET_SECOND_TIER: `${BaseURL.TIRES}/Master/GetSecondTier`,
  GET_THIRD_TIER: `${BaseURL.BASE_URL}/Admin/GetThirdTier`,
  GET_THIRD_TIER_AND_STYLE: `${BaseURL.TIRES}/Master/GetStyles`,
  GET_STYLE: `${BaseURL.BASE_URL}/Admin/GetStyle`,
  GET_CAMERA_VIEW: `${BaseURL.TIRES}/Master/GetCameraView`,
  GET_CAMERA_VIEW_URL: `${BaseURL.TIRES}/Master/GetCameraViewUrls`,
  GET_STYLIZE_FILTERS_LIST: `${BaseURL.BASE_URL}/Admin/GetFilters`,
  GET_ALL_IMAGES_CURRENT_USER_GALLERY: `${BaseURL.BASE_URL}/Accounts/GetAllImagesCurrentUser`,
  GET_ALL_IMAGES_CURRENT_USER_GALLERY_MONGO: `${BaseURL.MONGO_DB}/CrudRouters/currentUserProjects`,
  GET_ALL_IMAGES_GALLERY_MONGO: `${BaseURL.MONGO_DB}/CrudRouters/projects`,
  GET_ALL_IMAGES_PROJECT_BY_ID_MONGO: `${BaseURL.MONGO_DB}/CrudRouters/allRecords`,
  SCAN_QR_CODE: `${BaseURL.BASE_URL}/Accounts/GetUserInfo`,
  CALL_AWS_URL: `${BaseURL.BASE_URL}/Accounts/CallAWSURL`,
  CHECK_MULTIPLE_LOGINS: `${BaseURL.BASE_URL}/Workflow/MultipleLogin`,
  UPLOAD_MEDIA: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/UploadMedia`,
  DELETE_IMAGES_GALLERY: `${BaseURL.MONGO_DB}/CrudRouters/updateProjectMedia`,
  GET_LATEST_VERSION: `${BaseURL.BASE_URL}/Workflow/LatestVersion`,
  GET_LATEST_PROJECT_ID: `${BaseURL.BASE_URL}/Accounts/GetLatestProject`,
  GET_CHAT_ROOMS: `${BaseURL.CHAT_GPT}/Gpt/getRooms`,
  ADD_CHAT_ROOM: `${BaseURL.CHAT_GPT}/Gpt/rooms`,
  UPDATE_CHAT_ROOM_NAME: `${BaseURL.CHAT_GPT}/Gpt/updateRoomName`,
  DELETE_CHAT_ROOM: `${BaseURL.CHAT_GPT}/Gpt/deleteRoom`,
  SEND_CHAT_ROOM_MESSAGE: `${BaseURL.CHAT_GPT}/Gpt/chatMessages`,
  GET_CHAT_ROOM_MESSAGE: `${BaseURL.CHAT_GPT}/Gpt/getMessages`,
  GET_USER_SUBSCRIPTOIN_DETAILS: `${BaseURL.SUBSCRIPTION}/PaymentTracker/UserGenerationDetails`,
  GET_USER_SUBSCRIPTION_AND_ADDONS: `${BaseURL.SUBSCRIPTION}/Subscription/GetUserMembershipAndAddOns`,
  GET_ALL_SUBSCRIPTION_PLANS: `${BaseURL.SUBSCRIPTION}/Subscription/Memberships`,
  GET_ALL_ADDON_PLANS: `${BaseURL.SUBSCRIPTION}/Subscription/Addons`,
  BUY_SUBSCRIPTION_PLAN: `${BaseURL.SUBSCRIPTION}/Subscription/CreateUserMemberships`,
  BUY_ADDON_PLAN: `${BaseURL.SUBSCRIPTION}/Subscription/CreateUserAddon`,
  GET_BILLING_DETAILS: `${BaseURL.SUBSCRIPTION}/Subscription/GetUserBillingDetails`,
  GET_USER_PRIVILAGES: `${BaseURL.SUBSCRIPTION}/Subscription/GetUserPrivileges`,
  CANCEL_USER_MEMBERSHIP: `${BaseURL.SUBSCRIPTION}/Subscription/CancelMembership`,
  GET_MEMBERSHIP_SWITCH_DETAILS: `${BaseURL.SUBSCRIPTION}/Subscription/GetMembershipSwitchDetails`,
  GET_SWITCH_STEALTH: `${BaseURL.SUBSCRIPTION}/Subscription/SwtichStealth`,
  GET_STEALTH_DATA: `${BaseURL.SUBSCRIPTION}/Subscription/GetStealthMode`,
  GET_ADDON_POPUP_DATA: `${BaseURL.SUBSCRIPTION}/Subscription/GetAddonSwitchDetails`,
  MAKE_NEW_PAYMENT: `${BaseURL.PAYMENT}/PaymentGateway/MakeNewPayment`,
  DECODE_URL: `${BaseURL.PAYMENT}/PaymentGateway/DecodeUrl`,
  GET_ALL_FAQS: `${BaseURL.BASE_URL}/Admin/GetFaqData`,
  GET_ONE_RECORD: `${BaseURL.MONGO_DB}/CrudRouters/getOneRecord`,
  GET_CURRENT_USER_EXAMPLE_PROJECTS: `${BaseURL.BASE_URL}/Accounts/GetAllExampleProjectsUser`,
  GET_OEM_INFO: `${BaseURL.TIRES}/Master/GetOemInfo`,
  CREATE_NEW_TEAM: `${BaseURL.TEAMS}/Team/CreateUserTeam`,
  GET_ALL_USER_TEAMS: `${BaseURL.TEAMS}/Team/GetUserTeams`,
  GET_USER_TEAM_DETAILS: `${BaseURL.TEAMS}/Team/GetUserTeamDetails`,
  DELETE_USER_TEAM: `${BaseURL.TEAMS}/Team/DeleteUserTeam`,
  SEARCH_USER: `${BaseURL.TEAMS}/Team/SearchUser`,
  GET_USER_DETAILS_OF_TEAM: `${BaseURL.TEAMS}/Team/TeamMemberDetail`,
  INVITE_EXISTING_USER: `${BaseURL.TEAMS}/TeamCollab/SendTeamCollab`,
  GET_USER_TEAMS_INVITES: `${BaseURL.TEAMS}/TeamCollab/GetUserInvites`,
  ACCEPT_OR_REJECT_TEAM_INVITES: `${BaseURL.TEAMS}/TeamCollab/AcceptOrRejectTeamInvites`,
  SEND_INVITE_NEW_USER: `${BaseURL.TEAMS}/TeamCollab/SendTeamCollabNewUser`,
  GET_TEAM_NOTIFICATION: `${BaseURL.TEAMS}/TeamCollab/GetTeamNotifications`,
  EXIT_FROM_TEAM: `${BaseURL.TEAMS}/TeamCollab/ExitFromTeam`,
  REMOVE_USER_FROM_TEAM: `${BaseURL.TEAMS}/TeamCollab/RemoveUserFromTeam`,
  GET_ALL_PRIVATE_MODEL: `${BaseURL.WORKFLOW_BASE_URL}/Prompts/GetPrivateModel`,
  GET_ALL_TEAM_DETAILED_DATA: `${BaseURL.TEAMS}/Team/TeamDetailedData`,
  GET_ALL_TEAM_GENERATION_DATA: `${BaseURL.TEAMS}/Team/TeamProductUsage`,
  GET_ALL_GRAPHIC_STYLE_DATA: `${BaseURL.TEAMS}/Master/GetGraphicStyle`,
  GET_ALL_USERS_LIST: `${BaseURL.ADMIN_FLOW}/Admin/GetAllUsers`,
  GET_ALL_USERS_OF_MASTER_TEAM_NOT_JOINED_SUB_TEAM: `${BaseURL.TEAMS}/Team/GetUserSubTeamNotJoined`,
  GET_ALL_TUTORIALS_LIST: `${BaseURL.MONGO_DB}/CrudRouters/getTutorials`,
  GET_TUTORIAL_DETAILS_BY_ID: `${BaseURL.MONGO_DB}/CrudRouters/GetOneTutorialDetails`,
  UPDATE_LANG_VERSION: `${BaseURL.BASE_URL}/Workflow/SwitchLanguageAndStoreUserVersion`,
  GET_TEAM_NOT_PRESENT_USERS: `${BaseURL.TEAMS}/Team/GetCurrentTeamAndNotPresentUsers`,
  GET_USERS_DATA_OF_TEAM: `${BaseURL.TEAMS}/Team/TeamMembersData`,
  REMOVE_USER_FROM_ALL_TEAMS: `${BaseURL.TEAMS}/TeamCollab/RemoveUserFromAllTeamsCreator`,
  GET_MY_PRIVILEGE_SUMMARY: `${BaseURL.SUBSCRIPTION}/PaymentTracker/PlanPrivilegeSummary`,
  GET_USER_TEAMS_ORDER: `${BaseURL.SUBSCRIPTION}/GenerateWorkflow/UserTeamsOrder`,
  GET_ADMIN_DATA_MY_SQL: `${BaseURL.MONGO_DB}/CrudRouters/GetAdminDataMysql`,
  GET_ADMIN_DATA_MONGO: `${BaseURL.MONGO_DB}/CrudRouters/GetAdminDataMongo`,
  GET_GENERATION_SEED_DATA: `${BaseURL.TIRES}/Master/getProjectMediaWithSpecifications`,
  GET_ALL_TEAM_NOTIFICATIONS: `${BaseURL.TEAMS}/Team/AllTeamNotifications`,
  GET_ALL_GALLERY_TEAMS: `${BaseURL.BASE_URL}/Accounts/TeamListForGallery`,
  GET_ALL_GALLERY_TEAM_PROJECTS: `${BaseURL.BASE_URL}/Accounts/ProjectsListForGallery`,
  UPLOAD_ANY_FILE: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/UploadAnyMedia`,
  GET_CHAT_MESSAGE: `${BaseURL.CHAT_GPT}/Gpt/chatMessageV1`,
  GET_MAGIC_PROMPT_DATA: `${BaseURL.TIRES}/Master/magicPrompt`,
  UPDATE_TEAM_AVATAR: `${BaseURL.TEAMS}/Team/ChangeTeamIcon`,
  PROVIDE_ADDON_ON_EXHIBITION: `${BaseURL.SUBSCRIPTION}/PaymentTracker/ProvideExhibitionAddonByAdmin`,
  USER_HAVE_SPECIAL_ADDON: `${BaseURL.SUBSCRIPTION}/PaymentTracker/UserHasSpecialAddon`,
  GENERATE_IMAGE: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/generateImage`,
  INTERRUPT_GENERATION: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/interruptGeneration`,
  ABORT_GENERATION_REQUEST: `${BaseURL.WORKFLOW_BASE_URL}/GenerateWorkflow/abortRequest`,
};
export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
  PUT: "PUT",
};
